<!--
 * @Author: bashuang
 * @Date: 2023-11-9
 * @Description: 关于我们页面-发展历程
-->
<template>
  <div class="developHistory">
    <div class="module-tit">{{ '发展历程' | sLang }}</div>
    <ul class="history-list-wrap">
      <li class="history-list" v-for="(item1,index1) in historyList" :key="index1">
        <div class="left-node left">
          <div class="node">{{ item1.node | sLang }}</div>
        </div>
        <div class="right-node left">
          <div class="node-time">{{ item1.time }}</div>
          <div class="node-part" :class="item2.isMark ? 'node-mark' : ''" v-for="(item2,index2) in item1.part" :key="index2">
            {{ item2.text | sLang}}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'developHistory',
  props: {
    title: '',
  },
  data(){
    return{
      historyList:[
        {node:'2015', time:'5月', part:[{isMark: true, text: '香港保险经纪公司牌照收购并正式更名'},{isMark:true, text: '保险专家团队体系化人才矩阵形成'}]},
        {node:'2019', time:'6月', part:[{text: '大力发展港险业务，与8家头部保险公司签署合作协议'}]},
        {node:'2019', time:'9月', part:[{text: '单月新增首年保费跃升至亿级规模'}]},
        {node:'2019', time:'10月', part:[{text: '入驻香港尖沙咀甲级写字楼K11 ATELIER新职场'}]},
        {node:'2023', time:'6月', part:[{text: '公司稳健发展，累计服务客户数超1.6万'}]},
      ]
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang='scss' scoped>
.developHistory {
  width: 100%;
  .module-tit{
    font-size: 0.22rem;
    // font-family: HiraginoSansGB-W5, HiraginoSansGB;
    font-weight: 500;
    color: #333333;
    margin-bottom: 0.4rem
  }
  .history-list-wrap{
    height: auto;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      width: 0.01rem;
      height: 84%;
      border-left: 0.01rem solid #0066CC;
      left:6.5%;
      top:0.20rem;
    }
    .history-list{
      overflow: hidden;
      padding-bottom: 0.80rem;
      position: relative;
      &:nth-last-of-type(1){
        &::before{
          display: none;
        }
        &::after{
          display: none;
        }
      }
      &::after{
        content: "";
        position: absolute;
        left:0.43rem;
        top: 68%;
        transform: translateY(-50%);
        width: 0.14rem;
        height: 0.14rem;
        background: #0066CC;
        box-shadow: 0.00rem 0.02rem 0.04rem 0.00rem #93C9FF;
        border: 0.01rem solid #0066CC;
        border-radius: 50%;
      }
      &::before{
        content: "";
        position: absolute;
        left:0.39rem;
        top: 68%;
        transform: translateY(-50%);
        width: 0.22rem;
        height: 0.22rem;
        background: #FFFFFF;
        box-shadow: 0.00rem 0.02rem 0.04rem 0.00rem #93C9FF;
        border: 0.01rem solid #0066CC;
        border-radius: 50%;
      }
    }
    .left-node{
      height: 100%;
      margin-right: 0.61rem;
      margin-top: 0.20rem;
      width: 13%;
      .node{
        width: 100%;
        height: 0.40rem;
        background: #0066CC;
        line-height: 0.26rem;
        font-size: 0.22rem;
        font-family: DINAlternate-Bold, DINAlternate !important;
        font-weight: bold;
        color: #FFFFFF;
        padding-top:0.07rem;
        text-align: center;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          right: -0.51rem;
          bottom: 50%;
          width: 0.42rem;
          height: 0.01rem;
          border-top: 0.01rem solid #0066CC;
          z-index:10;
        }
      }
      
    }
    .right-node{
      height: auto;
      background: #F7F8FA;
      padding:0.2rem 0.2rem;
      width: 78%;
      &:hover{
        background: #0066CC;
        .node-time,.node-part{
          color:#ffffff;
        }
        .node-mark{
          &::before{
            border-left:0.07rem solid #ffffff;
          }
        }
      }
      .node-time{
        height: 0.18rem;
        font-size: 0.16rem;
        // font-family: HiraginoSansGB-W6, HiraginoSansGB;
        font-weight: 600;
        color: #333333;
        line-height: 0.18rem;
        border-left: 0.04rem solid #E4A74B;
        text-indent: 0.15rem;
        margin-bottom: 0.15rem
      }
      .node-part{
        font-size: 0.14rem;
        // font-family: HiraginoSansGB-W3, HiraginoSansGB;
        font-weight: normal;
        color: #333333;
        line-height: 0.22rem;
        transform: scale(0.8);
        transform-origin: left
      }
      .node-mark{
        text-indent: 0.20rem;
        position: relative;
        &::before{
          content:'';
          position: absolute;
          left:0 ;
          top:50%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border:0.04rem solid transparent;
          border-left:0.07rem solid #333333;

        }
      }
    }
  }
}
</style>