<template>
  <div class="about-us-box">
    <div class="about-banner">{{ '关于我们' | sLang }}</div>
    <div class="about-content1 w1200">
        <div class="us-menu-wrap left">
          <div class="menu-head">{{ '关于我们' | sLang }}</div>
          <el-menu
            :default-active="'1-1'"
            :default-openeds="openeds"
            class="el-menu-vertical-demo"
            @select="handleSelect"
            background-color="#F7F8FA"
            text-color="#333333"
            active-text-color="#0066CC">
              <el-menu-item :index="item2.indexNum2" v-for="(item2,index2) in menuList" :key = "item2.indexNum2">
                {{ item2.tit | sLang }}
                <i class="el-icon-arrow-right"></i>
              </el-menu-item>
          </el-menu>
        </div>
        <div class="us-right-con left">
          <companyProfile v-show="showContant==0"></companyProfile>
          <businessScope v-show="showContant==1"></businessScope>
          <developHistory v-show="showContant==2"></developHistory>
          <companyCulture v-show="showContant==3"></companyCulture>
        </div>
      </div>
    <my-foot @changePage="changePage" id="anchor_3" />
  </div>
</template>

<script>
import '../../assets/css/aboutUs/index.scss'
import myFoot from '@/components/myFoot/index.vue'
import businessScope from './components/businessScope.vue'
import companyCulture from './components/companyCulture.vue'
import companyProfile from './components/companyProfile.vue'
import developHistory from './components/developHistory.vue'
export default {
  name: 'aboutUs',
  data() {
    return {
      menuList: [
        { tit: '公司简介', indexNum2: '1-1' },
        { tit: '业务范围', indexNum2: '1-2' },
        { tit: '发展历程', indexNum2: '1-3' },
        { tit: '企业文化', indexNum2: '1-4' }
      ],
      openeds: ['1', '2'], // 导航栏菜单默认开启项
      showContant: 0 // 右边显示图片和文字
    }
  },
  components: {
    myFoot,
    businessScope,
    companyCulture,
    companyProfile,
    developHistory,
  },
  mounted() {
    this.handleSelect('1-1')
  },
  methods: {
    itemImg(id) {
      return require(`@/assets/img/service/about_con_${id}.png`)
    },
    changePage(i) {
      this.$store.commit('changePage', i)
    },
    handleSelect(key, keyPath) {
      let arr = key.split('-')
      let index1 = (arr[1] * 1) - 1
      this.showContant = index1
      document.documentElement.scroll({ top: 0, behavior: 'smooth' })
      document.body.scrollTop = 0
    }
  },
}
</script>
