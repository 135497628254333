<!--
 * @Author: bashuang
 * @Date: 2023-11-9
 * @Description: 关于我们页面-企业文化
-->
<template>
  <div class="companyCulture">
    <div class="module-tit">{{ '企业文化' | sLang }}</div>
    <div class="culture-list-wrap">
      <div class="culture-list" v-for="(item,index) in cultureList" :key="index">
        <div class="head-wrap">
          <div class="head-tit">{{ item.tit | sLang }}</div>
          <div class="head-tit-en">{{ item.enTit | sLang }}</div>
        </div>
        <div class="culture-content">
          <span>{{ item.texts | sLang }}</span>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'companyCulture',
  props: {
    title: '',
  },
  data(){
    return{
      cultureList:[
        {tit:'使命',enTit:'MISSION',texts:'通过产品、科技、投顾三大引擎，秉承客户至上的初衷，做好家庭风险管理，成就美好生活。'},
        {tit:'愿景',enTit:'VISION',texts:'成为立足香港、服务全球、市场领先的专业保险经纪机构。'},
        {tit:'经营理念',enTit:'PHILOSOPHY',texts:'创新驱动、科技引领、价值创造、协同共赢'},
        {tit:'核心价值观',enTit:'VALUES',texts:'汇通四海、达泽万家'},
      ]
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang='scss' scoped>
.companyCulture {
  
  width: 100%;
  .module-tit{
    font-size: 0.22rem;
    // font-family: HiraginoSansGB-W5, HiraginoSansGB;
    font-weight: 500;
    color: #333333;
    margin-bottom: 0.30rem
  }
  .culture-list-wrap{
    overflow: hidden;
  }
  .culture-list{
    width: 22%;
    margin-right: 3%;
    float: left;
    &:nth-last-of-type(1){
      margin: 0;
    }

    &:nth-child(2n-1){
      .head-wrap{
        background: #0066CC;
      }
      
    }
    &:nth-child(2n){
      .head-wrap{
        background: #EFA431;
      }
      .culture-content{
        background: linear-gradient(180deg, #FFF7E8 0%, #FFFFFF 100%);
        &::before{
          background: rgba(239,164,49,0.53);
        }
      }
    }
    &:nth-of-type(3) .head-tit-en{
      font-size: 0.38rem !important;
    }
    .head-wrap{
      height: 0.70rem;
      border-radius: 0.08rem 0.08rem 0.00rem 0.00rem;
      padding:0.24rem 0.21rem;
      position: relative;
      background: #0066CC;
      width: 100%;
      .head-tit{
        font-size: 0.2rem;
        // font-family: HiraginoSansGB-W5, HiraginoSansGB;
        font-weight: 500;
        color: #FFFFFF;
        letter-spacing: 0.02rem;
        width: 100%;;
      }
      .head-tit-en{
        position: absolute;
        width: 100%;;
        bottom:0;
        left:0.02rem;
        font-size: 0.38rem;
        height: 0.43rem;
        // font-family: HiraginoSansGB-W6, HiraginoSansGB;
        font-weight: 600;
        color: rgba(255,255,255,0.19);
        z-index:0;
        
      }
    }
    .culture-content{
      width: 100%;
      padding:0.62rem 0.22rem 0  0.22rem;
      text-align: justify;
      // font-family: HiraginoSansGB-W3, HiraginoSansGB;
      font-weight: normal;
      color: rgba(53, 54, 56, 0.9);
      line-height: 0.30rem;
      height: 3.20rem;
      position: relative;
      background: linear-gradient(180deg, #ECF2FF 0%, #FFFFFF 100%);
      span{
        display: block;
        width: 120%;
        font-size: 0.14rem;
        transform: scale(0.85);
        transform-origin: left;
      }
      &::before{
        content: '';
        position: absolute;
        top:0.36rem;
        left:11%;
        width: 1.33rem;
        background: rgba(1,114,190,0.26);
        height: 0.01rem;
      }
      
    }
  }

}
</style>