<!--
 * @Author: bashuang
 * @Date: 2023-11-9
 * @Description: 关于我们页面-业务范围
-->
<template>
  <div class="businessScope">
    <div class="module-tit">{{ '业务范围' | sLang }}</div>
    <div class="scope-wrap">
      <div class="main-scope left">
        <div class="main-tit">{{ '主营业务' | sLang }}</div>
        <div class="main-con">{{ '作为一家香港持牌的保险经纪公司，坚持以市场为导向、专业为支持、客户为中心，产品种类涵盖人寿、储蓄、医疗、危疾、意外、年金等保险类别，不断精益和创新产品及服务，为客户提供从健康保障、创富传承到品质生活的完善人生规划。' | sLang }}</div>
      </div>
      <div class="scope-classify left" @mouseleave="onMouseLeave('classifyMaskActive', -1)">
        <div class="classify" v-for="(item,index) in classifyList" :key="index"  v-lazy:background-image="require(`@/assets/img/aboutUs/classify_img${index+1}.png`)">
          <div class="classify-mask" @mouseenter="onMouseEnter('classifyMaskActive',index)" :class="classifyMaskActive ===index ? 'classify-mask-active': ''">
            <div class="classify-tit">{{ item.tit | sLang}}</div>
            <div class="classify-tit-en">{{ item.titEN | sLang}}</div>
            <div class="classify-content-text" v-show="classifyMaskActive ===index">
              {{ item.contentText | sLang}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'businessScope',
  props: {
    title: '',
  },
  data() {
    return {
      classifyList: [
        { tit: '危疾险',titEN:'CRITICAL ILLNESS INSURANCE', contentText:'预防大病医疗产生的费用支出，弥补收入损失、恢复患者信心，此外可以扩展多种保障责任，比如特定疾病、疾病终末期、老年护理金等延展保障。'  },
        
        { tit: '储蓄险',titEN:'SAVINGS INSURANCE',  contentText:'通过对资金合理的安排规划，获得长期资金增值。分红型产品可以分享保险公司的经营成果，兼顾保单拆分、货币转换、更换受保人等特色功能，也是有效的财富传承工具。'  },
        
        { tit: '万寿险',titEN:'UNIVERSAL LIFE INSURANCE', contentText:'同样拥有人寿保障及现金价值，可以根据自身情况调整缴费方式、投资比例以及领取方式，更有弹性及透明度更高，更适配资金有灵活调动需求的客户。'  },

        { tit: '年金险',titEN:'ANNUITY INSURANCE', contentText:'可以解决养老退休、子女教育等长期资金流规划问题，保证资金实现专款专用，为个人和家庭提供长期、持续、稳定的现金流。'  },

        { tit: '医疗险',titEN:'MEDICAL INSURANCE',  contentText:'以约定的医疗费用为赔付条件，用于解决医疗费用问题，由保险公司分担受保人的医疗开支，是对社会医疗险的有效补充。'  },

        { tit: '寿险',titEN:'LIFE INSURANCE', contentText:'保障稳定，性价比高，低保费撬动高保障杠杆，同时免体检额度较高，免责条款少，将收入损失风险合理转嫁给保险公司。'  },
      ],
      classifyMaskActive: -1, //鼠标划过重点显示
    }
  },
  mounted() {

  },
  methods: {
    onMouseEnter(params, i){
        
      this[params] = i
    },
    onMouseLeave(params, i, type) {
      // this.showWith = '50%';
      this[params] = i
    },
  }
}
</script>

<style lang='scss' scoped>
.businessScope {
  width: 100%;
  .module-tit{
    font-size: 0.22rem;
    // font-family: HiraginoSansGB-W5, HiraginoSansGB;
    font-weight: 500;
    color: #333333;
    margin-bottom: 0.30rem
  }
  .scope-wrap{
    overflow: hidden;
    .main-scope{
      width: 2.35rem;
      height: 5.00rem;
      padding:0.75rem 0.23rem;
      background: url('../../../assets/img/aboutUs/main_business.png') no-repeat right bottom / 0.45rem auto;
      background-color: #0066CC;
      margin-right: 0.02rem;
      .main-tit{
        height: 0.26rem;
        font-size: 0.2rem;
        // font-family: HiraginoSansGB-W5, HiraginoSansGB;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 0.26rem;
        background: url('../../../assets/img/aboutUs/arrow_right.png') no-repeat right 0.43rem center / 0.22rem 0.22rem;
        margin-bottom: 0.43rem;
      }
      .main-con{
        font-size: 0.14rem;
        // font-family: HiraginoSansGB-W3, HiraginoSansGB;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 0.25rem;
        letter-spacing: 0.01rem;
        text-align: justify;
        opacity: 0.85;
        transform: scale(0.85);
        transform-origin: left
      }
    }
    .scope-classify{
      width: 5.49rem;
      .classify{
        // display: inline-block;
        float: left;
        width: 32%;
        height: 2.48rem;
        margin:0 0.3% 0.04rem;
        background-image: url('../../../assets/img/aboutUs/classify_img1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .classify-mask{
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.25);
        box-sizing: border-box;
      }
      .classify-tit{
        font-size: 0.16rem;
        // font-family: HiraginoSansGB-W5, HiraginoSansGB;
        font-weight: 500;
        color: #FFFFFF;
        padding: 0.20rem 0.15rem 0;
        line-height: 0.20rem;
        margin-bottom: 0.08rem;
      }
      .classify-tit-en{
        width: 150%;
        font-size: 0.14rem;
        // font-family: HiraginoSansGB-W5, HiraginoSansGB;
        font-weight: 500;
        color: #FFFFFF;
        padding: 0.00rem 0.15rem 0;
        line-height: 0.10rem;
        margin-bottom: 0.1rem;
        transform: scale(0.73);
        transform-origin: left
      }
      .classify-content-text{
        padding-top:0.20rem;
        width: 1.90rem;
        margin-left:0.14rem;
        font-size: 0.14rem;
        // font-family: HiraginoSansGB-W3, HiraginoSansGB;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 0.2rem;
        border-top: 0.01rem solid rgba(255,255,255,0.4);
        position: relative;
        transform: scale(0.8);
        transform-origin: left;
        &::after{
          content: '';
          width: 0.19rem;
          height: 0.02rem;
          background: #ffffff;
          position: absolute;
          left:0;
          top:-0.02rem;
        }
      }
      .classify-mask-active{
        background: linear-gradient(180deg, #FB8A39 0%, #DC9E3F 100%);
        opacity: 0.84;
      }
    }
  }
}
</style>